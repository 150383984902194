<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo_new_white.png" alt="logo"></a>
                    <p style="margin-top:-10px">
                        Da oltre 30 anni investiamo con passione nella formazione per il futuro dei giovani e per lo sviluppo del territorio e delle imprese con cui lavoriamo.
                    </p>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/scuolalatecnica.it" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.instagram.com/scuolalatecnica.it/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://www.youtube.com/@ScuolaLaTecnica-tube" class="d-block" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        <li><a href="https://www.linkedin.com/company/scuola-la-tecnica?originalSubdomain=it" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Link</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/elenco-corsi">Elenco Corsi</a></li>
                        <li><a routerLink="/validita-attestati">Validità Attestato</a></li>
                        <li><a routerLink="/contatti">Contatti</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Login</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Discenti</a></li>
                        <li><a href="https://scuolalatecnica.discentya.it/login.php" target="_blank">Docenti</a></li>
                        <li><a href="https://scuolalatecnica.discentya.it/login.php" target="_blank">Partners</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Dove Siamo</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Via Falcone e Borsellino n. 1<br>82100 Benevento (Italia)</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+39082442927">Telefono: (+39) 0824-42927</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:info@scuolalatecnica.it">info@scuolalatecnica.it</a></li>
                        <li><i class='bx bxs-inbox'></i><a href="tel:+557854578964">Lunedì-Venerdì<br>9:30 – 13:00 / 15:00 – 19:00</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <p><i class='bx bx-copyright'></i>2024 Scuola la Tecnica - realizzato da <a target="_blank" href="https://www.discentya.it/">Discentya</a>, piattaforma per la formazione online</p>
                </div>
                <div class="col-lg-2 col-md-2">
                    <ul>
                        <li><a href="https://scuolalatecnica.it/privacy-policy/" target="_blank">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
